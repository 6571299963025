<template>
  <b-row class="auth-inner m-0">
    <b-col
      lg="4"
      class="d-flex align-items-center auth-bg p-2 mx-auto"
    >
      <b-card class="my-2">
        <transition mode="out-in">
          <b-col
            key="1"
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
          >
            <div
              class="
                w-100
                d-lg-flex
                align-items-center
                justify-content-center
                px-5
                is-displayed
              "
            >
              <b-img
                fluid
                :src="sideImg"
                alt="ART of Mentoring"
                class="mb-5"
                style="vertical-align: text-top"
              />
            </div>

            <!-- Register Form -->
            <validation-observer
              ref="registerForm"
              v-slot="{ pristine, invalid, handleSubmit }"
            >
              <b-form @submit.prevent="handleSubmit(onSubmit)">
                <b-row class="mb-1">
                  <b-col>
                    <h4 class="text-center">
                      Register Now
                    </h4>
                  </b-col>
                </b-row>

                <b-row class="mb-1">
                  <b-col>
                    <validation-provider
                      v-slot="validationContext"
                      ref="firstname"
                      rules="required"
                      vid="firstname"
                      name="First Name"
                    >
                      <b-form-group
                        label="First name"
                        label-for="firstname"
                      >
                        <b-form-input
                          id="firstname"
                          v-model="registerFields.firstname"
                          type="text"
                          placeholder="First Name"
                          name="firstname"
                          aria-label="First Name"
                          autocomplete="given-name"
                          aria-describedby="firstname-error"
                          :aria-invalid="
                            !getValidationState(validationContext)
                              ? 'true'
                              : 'false'
                          "
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback>
                          {{
                            validationContext.errors[0]
                          }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col>
                    <validation-provider
                      v-slot="validationContext"
                      ref="lastname"
                      rules="required"
                      vid="lastname"
                      name="Last Name"
                    >
                      <b-form-group
                        label="Last name"
                        label-for="lastname"
                      >
                        <b-form-input
                          id="lastname"
                          v-model="registerFields.lastname"
                          type="text"
                          placeholder="Last Name"
                          name="lastname"
                          aria-label="Surname"
                          autocomplete="family-name"
                          aria-describedby="lastname-error"
                          :aria-invalid="
                            !getValidationState(validationContext)
                              ? 'true'
                              : 'false'
                          "
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback>
                          {{
                            validationContext.errors[0]
                          }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>

                <b-row class="mb-1">
                  <b-col>
                    <validation-provider
                      v-slot="validationContext"
                      ref="email"
                      rules="required|email"
                      vid="email"
                      name="Email"
                    >
                      <b-form-group
                        label="Email"
                        label-for="email"
                      >
                        <b-form-input
                          id="email"
                          v-model="registerFields.email"
                          type="email"
                          placeholder="Email"
                          name="email"
                          aria-label="Email"
                          autocomplete="email"
                          aria-describedby="email-error"
                          :aria-invalid="
                            !getValidationState(validationContext)
                              ? 'true'
                              : 'false'
                          "
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback>
                          {{
                            validationContext.errors[0]
                          }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>

                <b-row class="mb-1">
                  <b-col>
                    <validation-provider
                      v-slot="validationContext"
                      ref="phone"
                      :rules="currentProgram.compulsory_phone_number ? 'aom_phone|required': 'aom_phone'"
                      vid="phone"
                      name="Contact Number"
                    >
                      <b-form-group
                        label="Phone"
                        label-for="phone"
                      >
                        <aom-tel-input
                          id="phone"
                          v-model="registerFields.phone"
                        />
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                        >
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>

                <b-row class="mb-1">
                  <b-col>
                    <validation-provider
                      v-slot="validationContext"
                      ref="password"
                      name="Password"
                      vid="password"
                      rules="required"
                    >
                      <b-form-group
                        label="Password"
                        label-for="password"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="
                            validationContext.errors.length > 0
                              ? 'is-invalid'
                              : null
                          "
                        >
                          <b-form-input
                            id="password"
                            v-model="registerFields.password"
                            :type="passwordFieldType"
                            name="password"
                            placeholder="Password"
                            :state="getValidationState(validationContext)"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              class="cursor-pointer"
                              :icon="passwordToggleIcon"
                              @click="togglePasswordVisibility"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <b-form-invalid-feedback>
                          {{
                            validationContext.errors[0]
                          }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>

                <b-row class="mb-1">
                  <b-col>
                    <validation-provider
                      v-slot="validationContext"
                      ref="password-confirmation"
                      name="Password Confirmation"
                      vid="password-confirmation"
                      rules="required|confirmed_password:password"
                    >
                      <b-form-group
                        label="Password Confirmation"
                        label-for="password"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="
                            validationContext.errors.length > 0
                              ? 'is-invalid'
                              : null
                          "
                        >
                          <b-form-input
                            id="password-confirmation"
                            v-model="registerFields.passwordConfirmation"
                            :type="passwordFieldType"
                            name="password-confirmation"
                            placeholder="Password Confirmation"
                            :state="getValidationState(validationContext)"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              class="cursor-pointer"
                              :icon="passwordToggleIcon"
                              @click="togglePasswordVisibility"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <b-form-invalid-feedback>
                          {{
                            validationContext.errors[0]
                          }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row
                  v-if="roleOptions.length"
                  class="mb-1"
                >
                  <b-col>
                    <validation-provider
                      v-slot="validationContext"
                      ref="preferred-role"
                      rules="required"
                      name="Preferred Role"
                    >
                      <b-form-group
                        label="Preferred Role"
                        label-for="preferred-role"
                      >
                        <v-select
                          id="preferred-role"
                          v-model="registerFields.roles"
                          :options="roleOptions"
                          item-text="name"
                          item-value="role_id"
                          label="name"
                          placeholder="Select the Preferred Role"
                          :multiple="multipleRolesEnabled"
                          :class="{ 'is-invalid': validationContext.errors.length > 0 }"
                          @close="validationContext.validate()"
                        />
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                        >
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row
                  v-if="isMenteePerMentorDisplayed"
                  class="mb-1"
                >
                  <b-col>
                    <validation-provider
                      v-slot="validationContext"
                      rules="required"
                      name="Number of mentees"
                    >
                      <b-form-group
                        label="Number of mentees"
                        label-for="number-of-mentees"
                      >
                        <v-select
                          v-model="menteePerMentor"
                          :options="menteePerMentorOptions"
                          label="name"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row class="mb-1">
                  <b-col>
                    <validation-provider
                      v-slot="validationContext"
                      ref="selectedLanguages"
                      rules="required"
                      name="Preferred Language"
                    >
                      <b-form-group
                        label="Preferred Language"
                        label-for="preferred-language"
                      >
                        <v-select
                          id="preferred-language"
                          v-model="registerFields.preferredLanguageId"
                          item-text="name"
                          item-value="id"
                          label="name"
                          :options="languageList"
                          placeholder="Select the Preferred Language"
                          :state="getValidationState(validationContext)"
                          :class="{ 'is-invalid': validationContext.errors.length > 0 }"
                          @close="validationContext.validate()"
                        />
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                        >
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>

                <b-row class="mb-1">
                  <b-col>
                    <validation-provider
                      v-slot="validationContext"
                      ref="accept-personal-information"
                      rules=""
                      name="Accept Personal Information"
                    >
                      <b-form-checkbox
                        id="accept-personal-information"
                        v-model="acceptPersonalInformation"
                        name="accept-personal-information"
                        value="accepted"
                        unchecked-value=""
                        :state="getValidationState(validationContext)"
                      >
                        I agree to the collection, use and storage of my
                        personal information for this program
                      </b-form-checkbox>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row class="mb-2">
                  <b-col>
                    <validation-provider
                      v-slot="validationContext"
                      ref="accept-terms-privacy"
                      rules="required"
                      name="Accept Terms Privacy"
                    >
                      <b-form-checkbox
                        id="accept-terms-privacy"
                        v-model="acceptTermsPrivacy"
                        name="accept-terms-privacy"
                        value="accepted"
                        unchecked-value=""
                        :state="getValidationState(validationContext)"
                      >
                        I agree to the
                        <b-link
                          :to="{ name: 'participant-terms' }"
                          class="text-primary"
                          target="_blank"
                        >
                          Terms of Acceptable Use
                        </b-link>
                        and
                        <b-link
                          :to="{ name: 'participant-privacy' }"
                          class="text-primary"
                          target="_blank"
                        >
                          Privacy Policy
                        </b-link>
                      </b-form-checkbox>
                    </validation-provider>
                  </b-col>
                </b-row>

                <b-row 
                  v-if="currentProgram.code_of_conduct_url"
                  class="mb-2"
                >
                  <b-col>
                    <validation-provider
                      v-slot="validationContext"
                      ref="accept-code-of-conduct"
                      rules="required"
                      name="Accept Code of Conduct"
                    >
                      <b-form-checkbox
                        v-model="acceptCodeOfConduct"
                        value="accepted"
                        unchecked-value=""
                        :state="getValidationState(validationContext)"
                      >
                        I agree to the
                        <b-link
                          :href="currentProgram.code_of_conduct_url"
                          target="_blank"
                          class="text-primary"
                        >
                          Code of Conduct
                        </b-link>
                      </b-form-checkbox>
                    </validation-provider>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col class="text-left">
                    <b-button
                      tabindex="0"
                      @click="cancel"
                    >
                      Cancel
                    </b-button>
                  </b-col>
                  <b-col class="text-right">
                    <b-button
                      type="submit"
                      variant="primary"
                      :disabled="pristine || invalid || isLoading"
                    >
                      <b-spinner
                        v-if="isLoading"
                        small
                      />
                      Register
                    </b-button>
                  </b-col>
                  <div class="clearfix" />
                </b-row>
              </b-form>
            </validation-observer>

            <b-card-text class="text-center mt-2">
              <b-link :to="{ name: 'participant-login', params: { clientSlug: subdomain, programPath: $route.params.programPath } }">
                <small>Already registered? Log in here</small>
              </b-link>
            </b-card-text>
          </b-col>
        </transition>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormCheckbox,
  BFormInput,
  BInputGroup,
  BForm,
  BFormInvalidFeedback,
  BButton,
  BInputGroupAppend,
  BImg,
  BCardText,
  BLink,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { getValidationState } from "@/libs/utils";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import usersService from "@/services/usersService";
import authService from "@/services/authService";
//eslint-disable-next-line
import { required, email } from "@validations";
import { makeErrorToast } from "@/libs/utils";
import { locales, localesDisplay, userCreationErrorCodes } from "@/models";
import { AOM_MAIN_DOMAIN } from '@/constants/app';
import { userRoles } from "@/models";
import { roleAlias } from "@/@aom-core/utils/utils";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BFormInput,
    BInputGroup,
    BFormInvalidFeedback,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BInputGroupAppend,
    BImg,
    BCardText,
    BLink,
    BSpinner,
    vSelect,
  },
  mixins: [togglePasswordVisibility],
  props: {
    programPath: {
      type: String,
      default: "",
    },
    subdomain: {
      type: String,
      default: window.location.host.split('.').slice(0, -2).join('.')
    }
  },
  data() {
    return {
      registerFields: {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        password: "",
        passwordConfirmation: "",
        preferredLanguageId: null,
        roles: []
      },
      acceptPersonalInformation: "",
      acceptTermsPrivacy: "",
      acceptCodeOfConduct: "",
      sideImg: require("@/assets/images/logo/logo-h.png"),
      isLoading: false,
      menteePerMentor:1
    };
  },
  created() {
    this.menteePerMentor = this.mentorMinMatchLimit;  // Set menteePerMentor to mentorMinMatchLimit when the component is created
  },

  setup() {
    return {
      getValidationState,
      roleAlias
    };
  },

  computed: {
    ...mapGetters("app", ["currentProgram"]),
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    menteePerMentorOptions() {
      return Array.from(
        { length: this.mentorMatchLimit - this.mentorMinMatchLimit + 1 },
        (_, i) => i + this.mentorMinMatchLimit
      );
    },
    mentorMatchLimit() {
      const role = this.currentProgram.program_roles.find(pr => pr.role_id === userRoles.MENTOR);
      return role ? role.match_limit : 10;
    },
    mentorMinMatchLimit() {
      const role = this.currentProgram.program_roles.find(program_role => program_role.role_id === userRoles.MENTOR);
      return role ? role.min_match_limit : 1;
    },
    languageList() {
      const defaultLanguagesList = [{
        id: locales.EN,
        name: localesDisplay[locales.EN]
      }];
      if (this.currentProgram.languages.length === 0) { return defaultLanguagesList; }
      const languagesList = this.currentProgram.languages.map(lang => ({
        id: lang.id,
        name: lang.name,
      }));
      const hasEN = languagesList.find(lang => lang.id === locales.EN);
      if (!hasEN) {
        return [...defaultLanguagesList, ...languagesList];
      }
      return languagesList;
    },
    selectedRoles () {
      return Array.isArray(this.registerFields.roles)? this.registerFields.roles: [this.registerFields.roles];
    },
    isMentorApplicationsOpen () {
      const regStatus = this.currentProgram.registration_status.applications.filter(r => r.role_id === userRoles.MENTOR);
      return regStatus[0]?.is_open;
    },
    isMenteeApplicationsOpen () {
      const regStatus = this.currentProgram.registration_status.applications.filter(r => r.role_id === userRoles.MENTEE);
      return regStatus[0]?.is_open;
    },
    isMenteePerMentorDisplayed () {
      return this.selectedRoles.filter(r => r.role_id === userRoles.MENTOR).length;
    },
    multipleRolesEnabled() {
      return this.currentProgram.multiple_roles_enabled;
    },
    roleOptions() {
      const roles = [];
      if (this.isMentorApplicationsOpen) {
        roles.push({
          role_id: userRoles.MENTOR,
          name: roleAlias(userRoles.MENTOR, this.currentProgram)
        });
      }
      if (this.isMenteeApplicationsOpen) {
        roles.push({
          role_id: userRoles.MENTEE,
          name: roleAlias(userRoles.MENTEE, this.currentProgram)
        });
      }
      return roles;
    }
  },
  watch: {
    menteePerMentor(n) {
      n = Math.max(1, Math.min(this.mentorMatchLimit, Math.round(n)));
      if(n) {
        this.registerFields.roles = this.selectedRoles.map(r => r.role_id === userRoles.MENTOR ? ({...r, match_limit: Number(n)}) : r);
      }
      this.menteePerMentor = n;
    }
  },
  methods: {
    cancel() {
      const protocol = window.location.protocol;
      const clientSlug = this.subdomain;
      const programPath = this.programPath;
      return window.location = `${protocol}//${clientSlug}.${AOM_MAIN_DOMAIN}/${programPath}`;
    },

    async onSubmit(isExistingAccount = false) {
      if (!this.currentProgram) {
        return false;
      }

      this.isLoading = true;

      try {
        let payload = null;
        let response = null;
        if (isExistingAccount) {
          payload = {
            email: this.registerFields.email,
          };

          response = await usersService.sendInviteToExistingParticipant(
            this.currentProgram.id,
            payload
          );
        } else {
          payload = {
            first_name: this.registerFields.firstname,
            last_name: this.registerFields.lastname,
            email: this.registerFields.email,
            password: this.registerFields.password,
            gdpr_consent: {
              has_consented: this.acceptPersonalInformation === "accepted"
            },
            password_confirmation: this.registerFields.passwordConfirmation,
            preferred_language_id: this.registerFields.preferredLanguageId.id,
            user_roles: this.selectedRoles
          };

          if (this.registerFields.phone) {
            payload["phone"] = this.registerFields.phone;
          }

          response = await usersService.registerParticipant(
            this.currentProgram.id,
            payload
          );
        }

        if (response) {
          this.$router.push({
            name: "participant-login",
            query: { register: 'participant' }
          });
        }
      } catch (e) {
        const { status, data } = e.response;
        if (status === 404 || status === 429 || status === 400) {
          this.$toast(makeErrorToast(data.message));
        } else if (status === 422 && data.errors) {
          this.$refs.registerForm.setErrors(data.errors);
        } else if (status === 409 && data.code === userCreationErrorCodes.EXISTING_USER_ALLOWED) {
          this.$bvModal
            .msgBoxConfirm('You may already have an account with us in another program. If you would like to join this program, please click Join. You will receive an email that requires you to verify this new role.', {
              title: 'Existing Account',
              size: 'sm',
              okVariant: 'primary',
              okTitle: 'Join',
              cancelTitle: 'Cancel',
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
            })
            .then(async value => {
              if(value) {
                this.onSubmit(true);
              }
            });
        } else if (status === 409) {
          this.$toast(makeErrorToast(data.message));
        } else {
          this.$log.error(e);
          this.$toast(makeErrorToast(e));
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.img-fluid {
  min-width: 50%;
  max-width: 80%;
  height: auto;
}

@media screen and (min-width: 993px) {
  .is-displayed {
    display: none !important;
  }
}

@import "@/assets/scss/vue/pages/page-auth.scss";
</style>